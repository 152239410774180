<template>
  <section class="main">
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p class="mutted">Cursos Publicados</p>
                <p class="success d-none">+ 2 esse mês</p>
              </div>
              <h2 v-if="cursos.length > 0">
                {{ cursos.length }}
                <small v-if="cursos.length == 1">publicado</small>
                <small v-else>publicados</small>
              </h2>
              <h5 v-else>
                <span v-if="!loading">Nenhum curso publicado</span>
                <div class="spinner-grow" role="status" v-else>
                  <span class="sr-only">Loading...</span>
                </div>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'pdv'">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Ganhos do Mês</p>
                <p class="success d-none">+ 28.4%</p>
              </div>
              <h2 class="tertiary">{{ totalAreceber | currency }}</h2>
            </div>
            
            <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'uc'">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Total de alunos do mês</p>
                <p class="success d-none">+ 28.4%</p>
              </div>
              <h2 class="tertiary">{{ totalAlunosMes }}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'pdv'">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p>Total vendido</p> 
                  <p class="success d-none">+ 12.2%</p>
                </div>
                <h2>{{ totalVendidocompleto | currency }}</h2>
              <small>
                Total de alunos
                <b>{{ totalAlunos }}</b>
              </small>
              </div>

              <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'uc'">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p>Total de alunos</p> 
                  <p class="success d-none">+ 12.2%</p>
                </div>
                <h2>{{ totalAlunos }}</h2>
              </div>
          </div>
        </div>
        <!-- <div class="col-md-8 pt-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body">
              {{datacollection}}
              <line-chart :chart-data="datacollection"></line-chart>
              <button @click="fillData()">Randomize</button>
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body"></div>
          </div>
        </div> -->
      </div>
      <CursosProfessor :role="group" />
    </div>

    <Dialog header="" :closable="false" :visible.sync="dialogOpen" :modal="true" :breakpoints="{ '960px': '90vw', '640px': '100vw' }" :style="{ width: '90vw' }">
      <div class="d-flex">
        <div class="text-left" style=" width: -webkit-fill-available; ">
          <h4>Tutorial Básico</h4>
        </div>
        <div class="text-right" style=" width: -webkit-fill-available; ">
          <button class="btn btn-sm btn-danger m-2" @click="dialogBtn=true">Fechar</button>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
          <!-- Vídeo Selecionado -->
          <div v-if="selectedVideo" class="video-container">
            <iframe
              :src="`https://player.vimeo.com/video/${selectedVideo.id}`"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
              style="width: 100%;height: 455px;"
            ></iframe>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
          <!-- Lista de Vídeos -->
          <ul style="width: auto; height: 455px; overflow-y: auto; border: 1px solid var(--primary); padding: 10px;">
            <li v-for="video in videos" :key="video.id" @click="playVideo(video)" class="text-center m-2 cursor-pointer">
              <div style=" border: 1px solid; background: var(--primary); color: white; padding: 1rem; " class="list-play" :class="{ 'li-ativa': video.id === selectedVideo.id }">
                <span class="material-icons" :alt="video.title" style="font-size: 45pt; ">play_circle</span>
                <p class="ml-0" style="font-weight: bold;font-size: 10pt;">{{ video.title }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Dialog header="Ação" :closable="false" :visible.sync="dialogBtn" :modal="true" :breakpoints="{ '500px': '90vw', '640px': '100vw' }" :style="{ width: '50vw' }">
        <div class="row">
          <div class="col-6 text-center">
            <button class="btn btn-md btn-secondary m-2" @click="CloseTarde">Exibir mais tarde</button>
          </div>
          <div class="col-6 text-center">
            <button class="btn btn-md btn-warning m-2" @click="CloseTutorial">Não exibir mais</button>
          </div>
        </div>
      </Dialog>
    </Dialog>

  </section>
</template>
<script>
import CursosProfessor from "@/components/CursosListaProfessor.vue";
import LineChart from "../../components/LineChart";
import Dialog from "primevue/dialog";
export default {
  name: "Homeprofessor",
  components: {
    CursosProfessor,
    // eslint-disable-next-line vue/no-unused-components
    LineChart,
    Dialog
  },
  data() {
    return {
      dialogOpen: false,
      dialogBtn:false,
      videos: [
        { id: "1021035846", title: "Como cadastrar um aluno", thumbnail: "https://i.vimeocdn.com/video/1021035846_100.jpg" },
        { id: "1021037257", title: "Como gerar relatórios", thumbnail: "https://i.vimeocdn.com/video/1021037257_100.jpg" },
        { id: "1021039870", title: "Como criar curso", thumbnail: "https://i.vimeocdn.com/video/1021039870_100.jpg" },
        { id: "1021042837", title: "Configurações diveras", thumbnail: "https://i.vimeocdn.com/video/1021042837_100.jpg" },
        { id: "1021044819", title: "Compartilhar e ampliar porftólio", thumbnail: "https://i.vimeocdn.com/video/1021044819_100.jpg" },
        // Adicione mais vídeos conforme necessário
      ],
      selectedVideo: { id: "1021035846", title: "Como cadastrar um aluno", thumbnail: "https://i.vimeocdn.com/video/1021035846_100.jpg" },

       datacollection: null,
      chart: "",
      value: "",
      loading: false,
      cursos: [],
      faturas: [],
      totalVendido: 0,
      totalVendidocompleto: 0,
      totalAlunos: 0,
      totalAlunosMes: 0,
      totalAreceber:0
    };
  },
  computed: {
    group() {
      return this.$store.state.user.group;
    },
  },

  methods: {
    CloseTarde(){
      const time = { time: new Date().getTime()};
      localStorage.setItem("time", JSON.stringify(time));

      this.dialogBtn=false;
      this.dialogOpen=false;
    },
    CloseTutorial(){
      const time = { tutorial: true};
      localStorage.setItem("tutorial", JSON.stringify(time));

      this.dialogBtn=false;
      this.dialogOpen=false;
    },
    openDialog() {
      this.dialogOpen = true;
    },
    closeDialog() {
      this.dialogOpen = false;
      this.selectedVideo = null;
    },
    playVideo(video) {
      this.selectedVideo = video;
    },

    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    async getAlunos() {
      this.$http
        .get("/api/gestor/relatorios")
        .then((response) => 
        {
          var responseData =  response.data;
          this.totalAlunos = responseData["totalAlunos"];
          this.totalAlunosMes =  responseData["totalAlunosMes"];
        })
        .catch(() => {});
    },
    async getFaturas() {
      this.$http
        .get("/api/gestor/faturasgestor")
        .then((response) => 
        {
          var responseData =  response.data;
          this.totalCertificados = response.data.totalCertificados;
          this.totalAlunos = responseData["alunos"];
          this.totalAreceber = responseData["mes"];//PENDERE A RECEBER MÊS ATUAL
          this.totalVendidocompleto = responseData["all"];//TOTAL RECEBIDO
        })
        .catch(() => {});
    },
    getCursoDestaque() {
      this.loading = true;
      this.$http.get("/api/gestor/get/cursos/").then((response) => {
        this.cursos = response.data.entries;
        this.loading = false;
      });
    },
  },
  filters: {
    currency(valor) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(valor);
    },
  },
  mounted() {
    //  this.fillData()
    document.title = this.$titleSite;
    this.getCursoDestaque();
    
    if(this.$wlType === 'pdv'){
      this.getFaturas();
    }
    if(this.$wlType === 'uc'){
      this.getAlunos();
    }
    if(localStorage.getItem("tutorial") == null){
      if(localStorage.getItem("time") != null){
        if((JSON.parse(localStorage.getItem("time")).time + 3600000) < new Date().getTime()){
        //if((JSON.parse(localStorage.getItem("time")).time + 60000) < new Date().getTime()){
          this.dialogOpen = true;
        }else{
          this.dialogOpen = false;
        }
      }else{
        this.dialogOpen = true;
      }
    }

  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  min-height: 140px;
}

.li-ativa{
  background: var(--primary-tint) !important;
  transform: scale(1.08);
}
.li-ativa > p{
  color:white !important;
}
.list-play:hover{
  transform: scale(1.08);
}
</style>